<template>
  <UiPopup
    :model-value="modelValue"
    title="Delete user"
    description="This user has no active leads. He will be removed from rotations and filters."
    primary-button-text="Delete user"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="deleteUser"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'
import type { User } from '@/types'
const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  user: User
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})
const loading = ref(false)

const deleteUser = async () => {
  loading.value = true
  try {
    await useDeleteUser(Number(props.user.id))
    uiStore.showSnackBanner(`User ${props.user.name} was deleted and moved to users archive.`)
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
